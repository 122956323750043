<template>
  <article class="box">
    <h1 class="page-title">
      {{ localDocumentTitle }}
    </h1>

    <VCard>
      <VCardTitle>
        <VTextField
          v-model="search"
          append-icon="search"
          :label="$t('search.one')"
          hide-details
        />
      </VCardTitle>
      <VDataTable
        :headers="headers"
        :items="lessons"
        :loading="loading"
        item-key="key"
        :search="search"
        :page.sync="page"
        @update:page="updatePage"
      >
        <template #[`item.lesson_type`]="{ item }">
          <VIcon
            v-if="item.lesson_type === 'quiz'"
            :title="$t('quizzes.one')"
          >
            quiz
          </VIcon>

          <VIcon
            v-else
            :title="$t('essays.one')"
          >
            task
          </VIcon>
        </template>

        <template #[`item.lesson_title`]="{ item }">
          <RouterLink :to="{ name: 'training', params: { sessionID: item.session_id, stepID: item.step_id } }">
            {{ item.lesson_title }}
          </RouterLink>
        </template>

        <template #[`item.course_title`]="{ item }">
          {{ item.course_title }}
        </template>

        <template #[`item.status`]="{ item }">
          <div class="status">
            <VChip
              v-if="item.status.code === 'accepted'"
              :color="'success'"
              dark
            >
              {{ item.status.title }}
            </VChip>

            <VChip
              v-if="item.status.code === 'rejected'"
              :color="'error'"
              dark
            >
              {{ item.status.title }}
            </VChip>

            <span
              v-if="item.status.code === 'started'"
            >
              {{ item.status.title }}
            </span>

            <span
              v-if="item.is_completed"
            >
              {{ formatDate(item.completed_at) }}
            </span>
          </div>
        </template>

        <template #[`item.mark`]="{ item }">
          {{ item.mark }}
        </template>

        <template #[`item.action`]="{ item }">
          {{ getActionTitle(item) }}
        </template>

        <template #[`item.lesson_id`]="{ item }">
          <VBtn
            icon
            :to="{ name: 'messenger', query: { chat: getChatID(item) }, params: { title: item.lesson_title, courseID: item.course_id, courseTitle: item.course_title } }"
          >
            <VIcon>email</VIcon>
          </VBtn>
        </template>
      </VDataTable>
    </VCard>
  </article>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { getQuizzes, getEssays, getChats, getGroups } from '@/api/api'
import documentTitle from '@/mixins/documentTitle'
import { UDate } from '@/utils/date'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'

export default {
  name: 'TheMarks',
  mixins: [documentTitle],

  data () {
    return {
      documentTitle: 'marks.other',
      loading: true,
      chats: [],
      lessons: [],
      groups: [],
      search: '',
      page: 1
    }
  },

  computed: {
    ...mapGetters({
      now: getters.NOW,
      limits: getters.VENDOR_LIMITS,
    }),

    headers () {
      return [
        {
          text: this.$t('types.one'),
          align: 'center',
          value: 'lesson_type',
          sortable: false
        },
        {
          text: this.$t('lessons.one'),
          value: 'lesson_title',
          sortable: false
        },
        {
          text: this.$t('courses.one'),
          value: 'course_title',
          sortable: false
        },
        {
          text: `${this.$t('$app.lesson_status.one')}/${this.$t('$app.date_completion.one')}`,
          align: 'center',
          value: 'status',
          sortable: false
        },
        {
          text: this.$t('marks.one'),
          align: 'center',
          value: 'mark',
          sortable: false
        },
        {
          text: this.$t('$app.action.one'),
          value: 'action',
          sortable: false
        },
        {
          text: this.$t('$app.ask_question.one'),
          align: 'center',
          value: 'lesson_id',
          sortable: false
        }
      ]
    }
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      updateNow: actions.UPDATE_NOW
    }),

    formatDate: UDate.formatDate,

    async register () {
      try {
        const { quizzes = [], now } = await getQuizzes()
        const { essays = [] } = await getEssays()
        this.updateNow(now)

        const uniqAttempts = {}
        const isLastStepAttempt = (uniqAttempts, attempt) => (
          !uniqAttempts[attempt.step_id] ||
          uniqAttempts[attempt.step_id].session_id !== attempt.session_id ||
          new Date(attempt.accepted_at) > new Date(uniqAttempts[attempt.step_id].accepted_at)
        )

        ;([...(quizzes || []), ...(essays || [])]).forEach((item) => {
          if (isLastStepAttempt(uniqAttempts, item)) {
            uniqAttempts[item.step_id] = item
          }
        })

        this.lessons = Object.values(uniqAttempts).map((lesson, idx) => {
          lesson.key = idx
          return lesson
        }).reverse()

        if (!this.limits.messages_disabled) {
          const { chats } = await getChats()
          this.chats = chats || []
        }

        if (this.limits.groups_writs_available) {
          const { groups } = await getGroups()
          this.groups = groups || []
        }
      } finally {
        this.loading = false
        this.page = parseInt(this.$route.query.page || this.page)
      }
    },

    updatePage (page) {
      const query = Object.assign({}, this.$route.query, {
        page
      })
      this.$router.replace({ query }).catch(() => {})
    },

    getChatID (lesson) {
      let chatID = 0
      let groupChatIdx = 0

      this.groups.forEach((group, idx) => {
        if (group.course_id === lesson.course_id && !groupChatIdx) {
          groupChatIdx = idx + 1
        }
      })

      this.chats.forEach((chat) => {
        if (!chatID && chat.course_id === lesson.course_id && Math.floor((Date.parse(this.now) - Date.parse(chat.created_at)) / UDate.monthInMilliseconds) < 18) {
          if (groupChatIdx && Date.parse(this.groups[groupChatIdx - 1].created_at) > Date.parse(chat.created_at)) {
            chatID = this.groups[groupChatIdx - 1].chat_id
          } else {
            chatID = chat.id
          }
        }
      })

      if (!chatID && groupChatIdx) {
        chatID = this.groups[groupChatIdx - 1].chat_id
      }

      return chatID
    },

    getActionTitle (item) {
      let content = 'Переделать нельзя'
      if (item.order_status.code === 'training' && !item.is_required && item.status.code !== 'accepted') {
        content = 'Можно пропустить'
      } else if (item.order_status.code === 'training' && item.is_required && item.status.code === 'rejected') {
        content = 'Нужно переделать'
      } else if (item.order_status.code === 'training' && item.is_required && item.status.code === 'started') {
        content = 'Обязательно выполнить'
      }

      return content
    }
  }
}
</script>

<style lang="scss">
.v-application {
  table td {
    max-width: 30rem;
  }

  .v-chip {
    display: flex;
    justify-content: center;
  }

  .status {
    padding: 0.5rem 0;
  }
}
</style>
